<template>
  <tr>
    <td>{{ quick.id }}</td>
    <td>{{ quick.description }}</td>
    <td>
      <v-icon v-if="quick.inAnalyze" color="green">mdi-checkbox-marked-circle</v-icon>
      <v-icon v-if="!quick.inAnalyze" color="red">mdi-cancel</v-icon>
    </td>
    <td>
      <v-icon v-if="quick.analyzed" color="green">mdi-checkbox-marked-circle</v-icon>
      <v-icon v-if="!quick.analyzed" color="red">mdi-cancel</v-icon>
    </td>
    <td>
      <v-btn depressed color="secondary"
             @click="goToQuick(quick.id)">
        <v-icon class="mr-4">mdi-eye</v-icon>
        Show quick
      </v-btn>
    </td>
  </tr>
</template>

<script>

export default {
  name: 'QuickRow',
  props: {
    quick: {
      id: Number,
      description: String,
      analyzed: Boolean,
      inAnalyze: Boolean,
      timestamp: Number,
      uniqueId: Number,
      userId: Number,
    },
  },

  data() {
    return {};
  },

  methods: {
    goToQuick(quickId) {
      this.$router.push(
        {
          name: 'quickById',
          params: {
            quickId,
          },
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
